
*{
  margin: 0;
  padding: 0;
  font-family: var(--font);
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
}


@font-face {
  font-family: Cadiz;
  src: url("../public/Font/cadiz-regular-webfont.woff");
}

:root {
  --bg: #C35B5A;
  --bg: #f5a038;
  --bg: #122680;
  --bg: rgb(184, 41, 41);
  --bg: #000;
  --bg-cal: rgb(24, 194, 115);
  --bg-cal: rgb(184, 41, 41);
  --play-col: rgb(143, 16, 182);
  --play-col: rgb(90, 7, 65);
  --play-col: rgb(184, 41, 41)75;
  --play-col: #75f94d;
  --main-col: #75f94d;
  --sec-col: #943aa2;
  --sec-col-op: rgb(148, 58, 162, 0.7);
  --sec-col-op: rgba(189, 68, 207, 0.7);
  --font-col: #663399;
  --font-col: rgb(184, 41, 41);

  --bg-accent: #484a4d;
  --evTimeColor: pink;
  --nav-size: 60px;
  --border: 1px solid #474a4d; 
  --border-radius: 8px;
  --speed: 500ms;
  --bg-menu: #fff;
  --font: Cadiz;
}

.sec-col{
  color: var(--sec-col);
}

.main-col{
  color: var(--main-col);
}

ul {
  margin: 0;
  padding: 0;
 
}

a{
  /* color: var(--text-color); */
  text-decoration: none;
}
