.main-menu{
  height: 7em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin: 16px;  
}

.menu-container{
  height: 7em;
  position: fixed;
  width: 100vw;
  max-width: 100%;
}
.main-menu::-webkit-scrollbar {
  display: none;
}
.menu-dd::-webkit-scrollbar {
  display: none;  
}

.menu-btn{
  display: flex;
  justify-content: space-around;
  font-size: 2em;
  width: 12em;
}

.menu-btn a{
  color: var(--main-col);
  transition: all 700ms ease;
}

.pageMenu{
  background-color: rgb(128, 128, 128, 0.7);
  background-color: var(--sec-col-op);
  /* line-height: 30px; */
  position: absolute;
  padding: 1em;
  top: 10em;
  right: 2em;
  height: 45em;
  width: 800px;
  overflow: scroll;
}

.pageMenu::-webkit-scrollbar {
  display: none;
  color: transparent;
  scrollbar-width: none;
}

.menuBtn{
  color: black;
  position: relative;
}

@media (max-height:850px){
  .pageMenu{
    height: 10em;
    padding-bottom: 1113em;
    margin-bottom: 1111em;
  }
}

@media (max-width: 900px) {
  .pageMenu {
    position: absolute;
    top: 154px;
    right: 0em;
    left: 0em;
    /* width: 100; */
    width: calc(100vw - var(--scrollbarWidth));
    overflow-y: scroll;
    background-color: rgb(128, 128, 128, 0.8);
  }
  .main-menu {
    background-color: var(--sec-col);
    height: 7em;
    display: flex;
    width: calc(100vw - 16px);
    justify-content: space-between;
    align-items: center;
    position: absolute;
    font-size: 16px;
    width: 100%;
    margin: 0px;  
  }
  .menu-btn{
    /* border: #000 solid ; */
    position: absolute;
    background-color: var(--main-col);
    width: 100%;
    top: 111px;
  }
  .menu-btn a{
    color: #000;
  }
}

@media (min-width: 3200px) {
  .pageMenu{
    width: 950px;
    right: 0;
  }
  .main-menu {
    top: 0em;
    height: 7em;
    display: flex;
    width: calc(100vw - 20em);
    justify-content: space-between;
    align-items: center;
    position: absolute;
    font-size: 16px;
    width: 100%;
    margin: 0px;  
  }
  .menu-btn{
    display: flex;
    justify-content: baseline;
    justify-items: center;
    align-items: center;

    background-color: var(--main-col);
    width: 50%;
    width: 950px;
    top: 111px;
    height: 2em;
  }
  .menu-btn a{
    color: #000;
  }
}
