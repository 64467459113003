
.bg-visual{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 0;
   
    background-color: var(--bg);
  }
  
  