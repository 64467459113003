/* .App{
    position: relative;
    display: flex;
    border: solid 3px goldenrod;
    width: 100%;
    height: 100%;
    overflow: scroll;

} */

.App{
    position: absolute;
    z-index: 11;
    min-height: 100vh;
}

.mobileBG{
    background-image: url("./Images/img/hrSmallCrop.png");
    background-position: center 50%;
    position: absolute;
    min-width: 100vw;
    height: 100vh;
}
