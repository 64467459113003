.cal-container{
    /* grid-area: cal; */
    height: auto;
    min-height: auto;
    width: auto;
    transition: all 400ms;
    /* overflow-y: sc; */
    z-index: 1;
    margin-right: 2em;
    /* background-color: aliceblue; */
}

.cal-container::-webkit-scrollbar {
    display: none;
    color: transparent;
    scrollbar-width: none;
}

.calendar::-webkit-scrollbar {
    display: none;
    color: transparent;
    scrollbar-width: none;
}

.date{
    width: 100px;
    height: auto;
}

.calendar{
    font-family: sans-serif;
    /* overflow-y: scroll; */
    transition: all 1s;
    display: grid;
    grid-template-areas: 
                        "mon"
                        "tue"
                        "wed"
                        "thu"
                         "fri"
                         "sat"
                         "sun"
                         "."
    ;    
}

div#HIDE{
    display: none;
}
div#MON{
    grid-area: thu;
}

div#TUE{
    grid-area: thu;
}

div#WED{
    grid-area: thu;
}

div#THU{
    grid-area: thu;
}
div#FRI{
    grid-area: fri;
}
div#SAT{
    grid-area: sat;
}
div#SUN{
    grid-area: sun;
    border-bottom: 0;
    padding-bottom: 3em;
}




