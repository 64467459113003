
li{
    color: #000;
    text-decoration: none;
}

.evTime{
    margin-left: 1em;
    width: 2.5em;
    font-weight: 900;
}

.evName{
    margin-left: 1em;
}

.evContainer{
    min-height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.evDay{
    display: flex;
}

.dayHead{
    font-size: 40px;
    display: flex;
    writing-mode: vertical-rl;
    justify-content: center;
}


.weekday{
    min-height: 7em;
    margin: 1em;
    padding-bottom: 1.5em;
    display: flex;
    border-bottom: #000 solid 4px;
}
