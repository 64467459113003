.audio-container{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: flex-start;
    max-width: calc(100vw - 16em);
    max-width: 800px;
    transition: all ease-in 400ms;
    z-index: 0;
}

.audioPlayer{
    background-color: var(--sec-col);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 64px;
    width: 64px;
    margin-left: 1em;
}

.playButton{
    display: flex;
    padding-left: 16px;
    padding-right: 8px;
    padding-top: 13px;
    padding-bottom: 13px;
}


.marquee-container{
    margin-right: 0.5em;
}

.marqueeInfo{
    position: relative;
    font-size: 2em;
    color: var(--main-col);
    width: 100%;
    transition: all 500ms ease-in;
}

.playInfo{
    opacity: 0;
    background-color: var(--sec-col);
    height: 64px;
    max-width: 1110px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    justify-content: center;
}


.playButton .play{
    align-self: center;
    box-sizing: border-box;
    border: var(--main-col) 1px solid;
    /* width: 31px; */
    height: 40px;
    border-width: 19px 0px 19px 30px;
    border-color: transparent transparent transparent var(--main-col);
}

.playButton .pause{
    transition: all ease 500ms;
    align-self: center;
    box-sizing: border-box;
    background-color: var(--main-col);
    width: 40px;
    height: 40px;
    /* margin: 13px; */
}

.sliderContainer{
    width: 120px;   
    margin-left: 13px;
    margin-right: 52px;
    transition: all ease-out 200ms;
    /* opacity: 0; */
}

.show{
    transition: all ease-in 500ms;
    opacity: 1;
}


.volumeSlider{
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    background: var(--play-col);
    width: 100%;
    height: 0.5em;
    outline: none; /* Remove outline */
}
.volumeSlider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 0.8em; /* Set a specific slider handle width */
    height: 2em; /* Slider handle height */
    background: var(--play-col); /* Green background */
    cursor: pointer; /* Cursor on hover */
}

.volumeSlider::-moz-range-thumb {
      width: 0.8em; /* Set a specific slider handle width */
      height: 2em; /* Slider handle height */
      background: var(--play-col); 
      background: var(--play-col);
      border-radius: 0;
      border-color: transparent;
      cursor: pointer; /* Cursor on hover */
}

@media (max-width: 915px) {

    .marquee-container{
        margin-right: 1em;
    }
   .playInfo{
       max-width: 100%;
   }
}


@media (max-width: 1200px){
   
    .audio-container{
        max-width: 500px;
    }
    .playInfo{
        max-width: 600px;
    }
}
