.nextup{
    grid-area: nextup;
    width: 30em;
    min-height: 34em;
    min-height: fit-content;
    border: solid 1px royalblue;
}

.preview{
    z-index: -11;
    margin: 2em;
    font-size: 1em;
    bottom: 0;
    transition: ease 1s all;    
    position: fixed;
}

div.dpreview{ 
    min-width: calc(fit-content + 2px);
 }



.dpreview h1{
    font-size: 32px;
}


/* 
.previewAct span strong{
    margin: 1em;
} */

.prevTime{
    margin-right: 1em;
}

div.dpreview #SUN{
    padding-bottom: 0;
}


div.dpreview div.weekday{
    margin: 0;
    padding: 0;
    min-height: fit-content;

}

div.dpreview div.weekday div.evContainer .evDay .evName{
    font-style: italic; 
}
div.dpreview div.weekday div.evContainer .evDay .evTime{
    margin: 0;
    font-weight: bold;
}
div.dpreview div.weekday div.evContainer .evDay{
    color: var(--main-col);
}

div.dpreview div.weekday  h2{
    display: none;       
}

.preview:hover{
    cursor: pointer;
}

.preview{
    transition: all 2s;
    color: var(--play-col);
}

