.hallo{
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    transition: all 400ms;
    z-index: 1;
    margin-right: 1em;
}

.hallo::-webkit-scrollbar {
    display: none;
    color: transparent;
    scrollbar-width: none;
}

.halloHead{
    writing-mode: vertical-lr;
    font-size: 45px;
    font-weight: 900;
    margin-right: 2em;
    
}

.langBtn{
    display: flex;
    justify-content: end;
    cursor: pointer;
}

.infoText{
    font-size: 16px;
    transition: all 1s ease;
}

.info{
    width: 45em;
}

.links{
    margin-top: 2em;
    padding: 1em 0 1em 0;
    display: flex;
    flex-direction: column;
    height: 3em;
}

.links a{
    margin-bottom: 1em;
    color: #fff;
}

.bengel{
    position: absolute;
    z-index: -1;
    bottom: 3em;
    right: 2em;
    height: 300px;
    width: 200px;
}

@media  (max-width: 900px) {
    

    .halloHead{
        display: none;
    }
}